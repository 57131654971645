<template>
  <div>
    <StreamBarcodeReader ref="scanner" @decode="onDecode"></StreamBarcodeReader>

    <div style="padding: 20px">
      <a-button type="primary" block @click="back">
        <span style="logout"> <a-icon type="play-circle" /> Kembali </span>
      </a-button>
    </div>
  </div>
</template>

<script>
import { StreamBarcodeReader } from "vue-barcode-reader";
import axios from "axios";

export default {
  components: {
    StreamBarcodeReader,
  },
  computed: {
    profiles() {
      return this.$store.state.Auth.data;
    },
  },
  methods: {
    back() {
      this.$router.go(-1);
      this.$refs.scanner.codeReader.stream
        .getTracks()
        .forEach(function (track) {
          track.stop();
        });
    },
    onDecode(decodedString) {
      //console.log(this.profiles);
      // console.log(
      //   decodedString +
      //     "&USER_NAME=" +
      //     this.profiles.user.fullName +
      //     "&USER_EMAIL=" +
      //     this.profiles.user.email
      // );
      let getrul = decodedString.split("/");
      //console.log(getrul[2]);
      let URLs =
        getrul[2] == "sio"
          ? "https://toyotabooth-museumangkut.web.id/6891"
          : "";

      // alert(
      //   URLs +
      //     decodedString +
      //     "&USER_NAME=" +
      //     this.profiles.user.fullName +
      //     "&USER_EMAIL=" +
      //     this.profiles.user.email
      // );
      axios
        .get(
          URLs +
            decodedString +
            "&USER_NAME=" +
            this.profiles.user.fullName +
            "&USER_EMAIL=" +
            this.profiles.user.email
        )
        .then((response) => {
          this.$notification.success({
            message: "Anda masuk kedalam permainan",
          });
          this.$router.push({ name: "HomeWelcome" });
        });
    },
  },
};
</script>
